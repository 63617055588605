:root {
  /* 字体颜色 */
  --text-primary: #1890ff;
  --text-green: #55aa00;
  --text-orange: orangered;
  --text-error: red;
  --text-gry: #888;
  --text-gry2: #666666;
  --text-gry3: #555555;
  --text-white: #FFFFFF;
  --text-white1: #e7e7e7;
  --text-dark: #000;
  /* 背景色 */
  --bg-white: #FFFFFF;
  --bg-gry: #e3e3e3;
  --bg-gry2: #F7F7F7;
  --bg-green: #6cd800;
  --bg-primary: #1890ff;
  --bg-error: #FF6767;
  --bg-white2: #f7f8fa;
  /* 字体 */
  --fs-8: 8px;
  --fs-10: 10px;
  --fs-12: 12px;
  --fs-14: 14px;
  --fs-16: 16px;
  --fs-18: 18px;
  --fs-20: 20px;
  --fs-22: 22px;
  --fs-24: 24px;
  --fs-26: 26px;
  --fs-30: 30px;
  --fs-40: 40px;
  --fs-60: 60px;
  /* border */
  --border-primary: #007bff;
  --border-secondary: #6c757d;
  --border-success: #28a745;
  --border-info: #17a2b8;
  --border-warning: #ffc107;
  --border-danger: #dc3545;
  --border-light: #f8f9fa;
  --border-dark: #343a40;
  --border-white: #fff;
  --border-light2: #e6e6e7;
}
/* 字体颜色 */
.text-primary {
  color: #1890ff !important;
}
.text-green {
  color: #55aa00 !important;
}
.text-orange {
  color: orangered !important;
}
.text-error {
  color: red !important;
}
.text-gry {
  color: #888 !important;
}
.text-gry2 {
  color: #666666 !important;
}
.text-gry3 {
  color: #555555 !important;
}
.text-white {
  color: #FFFFFF !important;
}
.text-white1 {
  color: #e7e7e7 !important;
}
.text-dark {
  color: #000 !important;
}
.cursor-pointer {
  cursor: pointer;
}
/* 背景色 */
.bg-white {
  background-color: #FFFFFF;
}
.bg-gry {
  background-color: #e3e3e3 !important;
}
.bg-gry2 {
  background-color: #F7F7F7 !important;
}
.bg-green {
  background-color: #6cd800 !important;
}
.bg-primary {
  background-color: #1890ff !important;
}
.bg-error {
  background-color: #FF6767 !important;
}
.bg-white2 {
  background-color: #f7f8fa !important;
}
/* 字体 */
.fs-8 {
  font-size: 8px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
  line-height: 2;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-60 {
  font-size: 60px !important;
}
.lh-50 {
  line-height: 50px!important;
}
.lh-30 {
  line-height: 30px!important;
}
/* border */
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.border-light2 {
  border-color: #e6e6e7 !important;
}
.box-shadow {
  box-shadow: 1px 1px 10px 1px #dddddd;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
