.ant-layout-header {
  transition: 0.3s;
  height: 48px !important;
  background: #ffffff !important;
  line-height: 48px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0 15px;
}
.ant-layout-header .container {
  width: 100%;
}
.ant-layout-header .head-telphone {
  color: #333;
}
.ant-layout-header .btn-box .ant-btn {
  border: 1px solid #fff !important;
  background-color: rgba(0, 0, 0, 0);
  color: #fff !important;
  margin-top: 5px !important;
}
.ant-layout-header .btn-box .ant-btn:hover {
  border-color: #2384c7 !important;
  background: #2384c7 !important;
  color: #fff !important;
}
.ant-layout-header .head-telphone {
  color: #fff;
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  float: right;
  margin-right: 15px;
}
.ant-layout-header .logo {
  display: inline-block;
  background: transparent !important;
  margin-right: 15px;
}
.ant-layout-header .logo img {
  height: 38px;
}
.ant-layout-header .ant-menu {
  display: inline-block;
  background: transparent !important;
  border-bottom: none !important;
}
.ant-layout-header .ant-menu > .ant-menu-item,
.ant-layout-header .ant-menu > .ant-menu-submenu {
  padding: 0 !important;
}
.ant-layout-header .ant-menu > .ant-menu-item:after,
.ant-layout-header .ant-menu > .ant-menu-submenu:after {
  display: none;
}
.ant-layout-header .ant-menu a {
  padding: 0 20px;
  line-height: 48px;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  color: #3d3d3d !important;
  font-size: 14px;
  font-weight: 700;
}
.ant-layout-header .btn-box {
  display: inline-block;
  float: right;
}
.ant-layout-header .btn-box .ant-btn {
  font-size: 14px;
  line-height: 1;
  margin-top: 15px;
  border-radius: 5px;
  transition: 0.3s;
  margin-left: 10px;
  background-color: #0084ff;
}
.ant-layout-header .btn-box .ant-btn:first-child {
  margin-left: 0;
}
.ant-layout-header::after {
  content: "\200B";
  display: block;
  height: 0;
  clear: both;
}
